import React, { useState, useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Widget from './style'
import img from "~assets/image/customer-stories/document-management.jpg"
import { SuperTag, Link } from '~components';

export default function ACIWidget(){
  
return(
      
<Widget>
  <Container>
  <Widget.Block>
    <Row className=" justify-content-lg-start">
    <Col xs="12" className="col-xl-6 col-lg-6 col-md-8 col-xs-10">
    <Widget.Image>
            <img  src={img} alt="content" layout="fullWidth" className="w-100" placeholder="blurred" />
        </Widget.Image>
      </Col>
    <Col className="col-xl-6 col-lg-6 col-md-8 col-xs-10">
        <Widget.Box>
        <Link to="/customer-stories"><Widget.MiniHeading><Widget.BackButton textColor="#000"><i class='fas fa-angle-left'></i>  Customer Stories</Widget.BackButton> </Widget.MiniHeading></Link>
          <Widget.Heading as="h2"> <SuperTag value="Transforming Document Management: ACI's Journey with We-Connect"/> </Widget.Heading>
          
        </Widget.Box>
      </Col>
    </Row>
    </Widget.Block>

    <Widget.Block>
    <Row className="justify-content-lg-start">
    <Col xs="12" className="col-xl-4 col-lg-4 col-md-8 col-xs-10">
        <Widget.Card>
        <Widget.Company as="h2">Ash Conversions International (ACI)</Widget.Company>
        <Widget.Text><a target="_blank" href="https://www.ashconversions.com/"> ACI </a> is revolutionizing document management through streamlined processes. By harnessing the power of automation, ACI significantly enhances business efficiency and performance.</Widget.Text>
        </Widget.Card>

        <Widget.Card>
        <Widget.SubPara> <b>Industry</b> </Widget.SubPara>
        <Widget.Text>Document Management</Widget.Text>
        </Widget.Card>

        <Widget.Card>
        <Widget.SubPara> <b>Employees</b> </Widget.SubPara>
        <Widget.Text>51-200</Widget.Text>
        </Widget.Card>

        <Widget.Card>
        <Widget.SubPara> <b>Features Used</b> </Widget.SubPara>
        <Widget.Text>Smart sequences, my contacts</Widget.Text>
        </Widget.Card>
      </Col>
    <Col className="col-xl-7 col-lg-7 col-md-8 col-xs-10">

        <Widget.Title as="h2"><b>Finding the Right Tool for Relationship Building:</b></Widget.Title>
        <Widget.Text>In their pursuit of more efficient marketing strategies, ACI experimented with various approaches. Initially allocating their budget to Google Ads yielded no significant results. A European sales enablement tool, despite leveraging multiple lead databases, also failed to generate responses from qualified prospects. ACI explored email copywriters and alternative automation tools without success.
        </Widget.Text>

        <Widget.Title as="h2"><b>Enter We-Connect:</b></Widget.Title>
        <Widget.Text>
        ACI's turning point came with the discovery of We-Connect. Describing the implementation as remarkably simple, they had their first campaign up and running within a day. Over a year later, they express their satisfaction: "We've stuck with [We-Connect] because A, you're not gouging on the price, and B, you help us meet our goals." They commend We-Connect for consistently adding valuable capabilities to the platform without increasing prices.
        </Widget.Text>


        <Widget.Title as="h2"><b>Establishing ACI as an Authority in Document Management:</b></Widget.Title>
        <Widget.Text>ACI aimed to position itself as an authority in document management. Employing invite connection campaigns with automatic withdrawal after 30 days, they witnessed a substantial increase in connections, averaging about 50 new connections monthly.</Widget.Text>
        <Widget.Text>With over 40 years in document management, ACI has transitioned its focus from paid ads to SEO and LinkedIn outreach. We-Connect played a pivotal role in presenting ACI's profile as an industry leader to new leads, evident in the growing follower count.</Widget.Text>

        <Widget.Title as="h2"><b>Future Endeavors with We-Connect:</b></Widget.Title>
        <Widget.Text>In their upcoming campaign, ACI plans to leverage We-Connect for a weekly message sequence sharing best practices in document management. Their emphasis is on relationship-building rather than direct sales, aiming to foster connections that can evolve into future sales.</Widget.Text>

        <Widget.CardTwo>
        <Widget.SubPara> Start your free trial and start kicking butt like this company did.</Widget.SubPara>
        <Widget.Button target="_blank" to="//account.we-connect.io/signup" className="btn btn-primary text-white">14 day free trial</Widget.Button>
        </Widget.CardTwo>
      </Col>
    </Row>
    </Widget.Block>
    
  </Container>
</Widget>

)
}